<template>
  <div>
    <water-nav selected="3"></water-nav>
    <div class="ly-center">
      <div class="time">更新时间：{{ pageData.records[0].createTime || '--' }}</div>
      <div class="ly-table-wrap">
        <div class="tb-line tb-tt">
          <div class="sort">序号</div>
          <div class="name">变量</div>
          <div class="value">值</div>
        </div>
        <div class="tb-body">
          <div class="tb-line" v-for="(item,index) in pageData.records" :key="item.id">
            <div class="sort">{{ index + 1 }}</div>
            <div class="name">
              <p class="zhName">{{ item.zhName }}</p>
              <p>{{ item.variableName }}</p></div>
            <div class="value" @click="handleGo(item)">
              <p class="vData">{{ item.variableData }}</p>
              <p>{{ item.variableUnit }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="ly-wrap">
      <ly-paging :pagination="pageData" @sizeChange="sizeChange" @pageChange="pageChange"></ly-paging>
    </div>
  </div>

</template>

<script>
import LyPaging from '../../components/LyPaging'
import pageMixin from "../../mixin/pageMixin";
import {getSelectWater} from "../../utils/auth";
import WaterNav from "./modules/WaterNav";
import storage from "../../store/storage";

export default {
  name: 'PumpQuery',
  mixins: [pageMixin],
  components: {WaterNav, LyPaging},
  data() {
    return {
      query: {
        waterId: ''
      },
      defaultLoad: false,
      tableUrl: '/water/variableData/queryPageDataByWaterId',
      pageData: {
        records: [
          {createTime: ''}
        ]
      },
      timer: null,
      interval: 5000
    }
  },
  created() {
    this.loadData()
  },
  methods: {
    async loadData() {
      const selectWater = await getSelectWater()
      this.query.waterId = selectWater.id
      this.loadPageData()
      this.setTimer()
    },
    handleGo(item) {
      storage.put('condition', item)
      this.$router.push({path: '/pump/query/curve'})
    },
    /** 定时器 */
    setTimer() {
      const _this = this
      this.timer = setInterval(() => {
        _this.loadPageData()
      }, _this.interval)
    },
    /** 关闭定时器 */
    closeTimer() {
      clearInterval(this.timer)
    },
  },
  destroyed: function () {
    this.closeTimer()
  },
}
</script>

<style scoped lang="less">
.time {
  padding-top: 0.26rem;
  font-size: 0.32rem;
  color: #0088FF;
}

.ly-table-wrap {
  width: 100%;
  height: auto;
  border-radius: 10px;
  overflow: hidden;
  margin-top: 0.2rem;
  padding-bottom: 3rem;

  .tb-body {
    width: 100%;
    height: auto;
    //overflow-y: scroll;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
  }

  .tb-line {
    height: 0.88rem;
    line-height: 0.88rem;
    background: #EDF6FF;
    border: 0px solid #000000;
    font-size: 0.28rem;
    font-weight: 400;
    color: #333333;
    display: flex;
    text-indent: 0.2rem;
    border-top: 1px solid #A6E1FFFF;

    .sort {
      width: 1.2rem;
    }

    .name {
      flex: 1;
      border-left: 1px solid #A6E1FFFF;
      border-right: 1px solid #A6E1FFFF;

      .zhName {
        padding-top: 0.1rem;
        font-size: 0.26rem;
        color: #0088FF;
      }

      p {
        line-height: 0.4rem;
        font-size: 0.24rem;
      }
    }

    .value {
      width: 2.85rem;
      color: #0088FFFF;
      background: url("../../assets/img/more-dk.png") no-repeat 2.4rem center;
      background-size: 0.2rem;

      .vData {
        padding-top: 0.1rem;
        color: #0088FF;
        font-size: 0.26rem;
      }

      p {
        line-height: 0.4rem;
        font-size: 0.24rem;
        color: #333333;
      }
    }

  }

  .tb-line:first-child {
    border-top: none;
  }

  .tb-tt {
    background: #69B9FF;
    color: #FFFFFF;

    .name {
      border-left: 1px solid #CFEAFFFF;
      border-right: 1px solid #CFEAFFFF;
    }

    .value {
      color: #FFFFFF;
      background: none;
    }
  }
}
</style>

